@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-ExtraboldItalic.woff2') format('woff2'), url('CaslonIonic-ExtraboldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-Extrabold.woff2') format('woff2'), url('CaslonIonic-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-BoldItalic.woff2') format('woff2'), url('CaslonIonic-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-Bold.woff2') format('woff2'), url('CaslonIonic-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-MediumItalic.woff2') format('woff2'), url('CaslonIonic-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-Medium.woff2') format('woff2'), url('CaslonIonic-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-RegularItalic.woff2') format('woff2'), url('CaslonIonic-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CaslonIonic';
  src: url('CaslonIonic-Regular.woff2') format('woff2'), url('CaslonIonic-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
