@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Black.woff2') format('woff2'), url('Yellix-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-BlackItalic.woff2') format('woff2'), url('Yellix-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-ExtraBold.woff2') format('woff2'), url('Yellix-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-ExtraBoldItalic.woff2') format('woff2'), url('Yellix-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Bold.woff2') format('woff2'), url('Yellix-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-BoldItalic.woff2') format('woff2'), url('Yellix-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-SemiBold.woff2') format('woff2'), url('Yellix-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-SemiBoldItalic.woff2') format('woff2'), url('Yellix-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Medium.woff2') format('woff2'), url('Yellix-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-MediumItalic.woff2') format('woff2'), url('Yellix-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Regular.woff2') format('woff2'), url('Yellix-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-RegularItalic.woff2') format('woff2'), url('Yellix-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Light.woff2') format('woff2'), url('Yellix-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-LightItalic.woff2') format('woff2'), url('Yellix-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Thin.woff2') format('woff2'), url('Yellix-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yellix';
  src: url('Yellix-Thin.woff2') format('woff2'), url('Yellix-Thin.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
